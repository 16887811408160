<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      LPCentre Web Application. V2.11.14
    </span>

    <span class="float-md-right d-none d-md-block">Made with
      💙 
      By <a 
      target="_blank" href="https://www.prokoders.com">ProKoders</a>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
