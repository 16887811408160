<template>
  <ul style="margin-bottom: 110px;">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
      
    />
    <!-- <li
  v-if="role.roles[0]=='Blogger'"
  class="nav-item"
  :class="{
    'active': isActive,
    'disabled': item.disabled
  }"
>

  <b-link
    v-bind="linkProps"
    class="d-flex align-items-center"
  >
    <b-icon icon="search" ></b-icon>
 
  
    <span class="menu-title text-truncate">Seo Plog</span>
    <b-badge
      v-if="item.tag"
      pill
      :variant="item.tagVariant || 'primary'"
      class="mr-1 ml-auto"
    >
      {{ item.tag }}
    </b-badge>
  </b-link>
</li> -->
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const role=JSON.parse(localStorage.getItem('userData'))
    provide('openGroups', ref([]))

    return {
      role,
      resolveNavItemComponent,
    }
  },
}
</script>
